@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'condensed-j';
    src: url('./assets/RobotoCondensed-Bold.ttf');
}
.j-font {
    font-family: 'condensed-j';
}

@font-face {
    font-family: 'condensed-j2';
    src: url('./assets/RobotoCondensed-SemiBold.ttf');
}
.j-font2 {
    font-family: 'condensed-j2';
}

@font-face {
    font-family: 'condensed-j3';
    src: url('./assets/RobotoCondensed-Regular.ttf');
}
.j-font3 {
    font-family: 'condensed-j3';
}

body {
    margin: 0px;
    overflow-x: hidden;
}

.bg-img {
    background-image: url(assets/tubes.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    top: 5rem;

}
.bg-img-second {
    background-image: url(assets/bg-img-second.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
}
.bg-img2 {
    background-image: url(assets/Polygon2.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: -300px -5px;
}

.bg-logo {
    background-image: url(assets/logo.png);
    height: 100%;
    width: 100%;
}

.button-main {
    display: inline-flex;
    align-items: center;
    background: rgb(236, 238, 240);
    border: #6d6969 0.3em solid;
    border-radius: 2.5em;
    padding-left: 5em;
    padding-right: 5em;
    padding-top: 1em;
    padding-bottom: 1em;
}

/*button glow properties*/

.button-second {
    /*padding: 1.2rem 5rem;*/
    border-radius: 1.3rem;
    border: 7px solid black;
    /*border: 1px solid red;*/
    background-color: #f0f0ee;
    color: #302c2c;
    font-size: 1.65rem;
    overflow: hidden;
}
/*
.button-second::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 2rem;
    background-color: #323231;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    filter: blur(18px);
    animation: button-animation 7s linear infinite;
}
.button-second::after {
    content: "444 444 444";
    position: absolute;
    left: 0;
    top: 0;
    width: 99%;
    height: 99%;
    background-color: #bcafaf;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
*/
@keyframes button-animation {
    0% {
        left: 0;
        top: 0;
    }
    35% {
        left: 100%;
        top: 0;
    }
    50% {
        left: 100%;
        top: 100%;
    }
    85% {
        left: 0;
        top: 100%;
    }
}


@media screen and (max-width: 600px) {
    /* Add your styles here */
    .button-small {
        display: inline-flex;
        align-items: center;
        background: transparent;
        border: #F6FB1A 0.3em solid;
        border-radius: 2em;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
        padding-bottom: 1em;
    }
  }

.button-main:hover {
    border: rgb(243 244 246) 0.3em solid;
  }

body {
    margin: 0;
}

.bullet {
  margin-right: 8px; /* Adjust margin as needed */
  color: #333; /* Color of bullet point */
}
ul {
  padding-left: 0;
}

li {
  display: flex;
  align-items: baseline; /* Adjust alignment as needed */
  list-style-type: none;
}
